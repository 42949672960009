body {
  overflow-x: hidden;
}
.App-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'General Sans';
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em; 
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
}

h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(85, 72, 111, 0.5);
}

p {
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left; 
}

.sub-p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

span {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.line {
  background-image: url('../public/line.png');
  background-repeat: repeat-y;
  width: 1px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.light-purple {
  background-color: #9077C6;
}

.light-purple-border {
  border-color  : #C8BBE4;
}

.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 3rem;
}

.is-hidden {
  display: none;
}

#footer-animation {
  height: auto!important;
}

.footer-gradient {
  background: linear-gradient(180deg, rgba(127, 67, 255, 0.05) 0%, rgba(127, 67, 255, 0.00) 100%);
}

#lottie-scroll{
  left: 0 !important;
  top: 0 !important;
  transform: none !important;
  position: relative !important;
}

#advantageLottieSection {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.pin-spacer {
  padding: 0 !important;
  height: fit-content !important;
}